import AddHumor from '@/components/molecules/add-humor';
import AddIllustration from '@/components/molecules/add-illustration';
import AddQuote from '@/components/molecules/add-quote';
import { SuccessResponse } from '@/components/molecules/my-account';
import SermonBible from '@/components/molecules/sermon-bible';
import SermonIllustrations from '@/components/molecules/sermon-illustrations';
import SermonNews from '@/components/molecules/sermon-news';
import SermonPrepBar from '@/components/molecules/sermon-prep-bar';
import SermonTools from '@/components/molecules/sermon-tools';
import SynAntTools from '@/components/molecules/syn-ant-tool';
import IllustrationsTabs from '@/components/search/illustrations-tabs';
import LectionaryTabs from '@/components/search/lectionary-tabs';
import MediaTabs from '@/components/search/media-tabs';
import Pagination from '@/components/search/pagination';
import SearchResult from '@/components/search/search-result';
import SermonAidsTabs from '@/components/search/sermon-aids-tabs';
import Sermon, { ContentSlugResponse } from '@/components/sermons/sermon';
import {
  AnnouncementsResponse,
  BibleBooks,
  BibleResponse,
  BookmarkedSermons,
  BulletinsResponse,
  ChaptersResponse,
  Content,
  ContentDto,
  ContentResponse,
  ContentWithSnippet,
  Denominations,
  ErrorResponse,
  HelpResponse,
  IllustrationsResponse,
  LectionarySearchResults,
  LexicalEntry,
  MediaContent,
  NewDictionaryWord,
  NewLectionaryWeeksByReading,
  NewWeeksResponse,
  Pericope,
  PreviewContent,
  SYN_ANT_SORT_OPTION,
  ScriptureSearchResults,
  SeriesCategoriesResponse,
  SoloTextAndTools,
  StudyTools,
  Suggestion,
  SynonymsAntonymsResponse,
  ThisWeek,
  User,
  Verse,
  VerseStringsResponse,
  Week,
  WeeksResponse,
} from '@/types/sermons';
import classNames from 'classnames';
import moment, { Moment } from 'moment-timezone';
import Image from 'next/image';
import Link from 'next/link';
import { NextRequest } from 'next/server';
import slugify from 'slugify';
import { Readable } from 'stream';
import uniqueRandomArray from 'unique-random-array';
//import { SERVER_TOKEN, SERVER_TZ } from './constants';
import { SERVER_TZ } from './constants';
export const fetcher = ([url, token, tags = []]: [
  string | null,
  string | null | undefined,
  string[] | undefined,
]) =>
  url === null
    ? Promise.resolve(null)
    : fetch(url, {
        // eslint-disable-next-line no-cond-assign
        headers: token
          ?
          {
              Authorization: `Bearer ${token}`,
              'content-type': 'application/json',
            }
          : {}
          ,
        cache: 'no-store',
        next: {
          tags: ['all', ...tags],
        },
      }).then(async (res) => {
        const data = await res.json();
        return data;
      });

export const postFetcher = ([url, body, token, tags = []]: [
  string | null,
  unknown,
  string | null | undefined,
  string[] | undefined,
]) =>
  url === null
    ? Promise.resolve(null)
    : fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        // eslint-disable-next-line no-cond-assign
        headers: //(token = SERVER_TOKEN || token)
          //?
          {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          //: {
          //    'Content-Type': 'application/json',
         //   }
         ,
        cache: 'no-store',
        next: {
          tags: ['all', ...tags],
        },
      }).then(async (res) => {
        const data = await res.json();
        return data;
      });

export const putFetcher = ([url, body, token, tags = []]: [
  string | null,
  unknown,
  string | null | undefined,
  string[] | undefined,
]) =>
  url === null
    ? Promise.resolve(null)
    : fetch(url, {
        method: 'PUT',
        body: JSON.stringify(body),
        // eslint-disable-next-line no-cond-assign
        headers: //(token = SERVER_TOKEN || token)
          //?
          {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          //: {
          //    'Content-Type': 'application/json',
          //  }
          ,
        cache: 'no-store',
        next: {
          tags: ['all', ...tags],
        },
      }).then(async (res) => {
        const data = await res.json();
        return data;
      });

export const BIBLE_TRANSLATIONS = [
  'niv',
  'esv',
  'rsv',
  'nrsv',
  'asv',
  'nasb',
  'kjv',
  'nkjv',
  'ampc',
] as const;
export type BIBLE_TRANSLATION = (typeof BIBLE_TRANSLATIONS)[number];

export const isBibleTranslation = (
  translation?: string | null,
): translation is BIBLE_TRANSLATION => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return BIBLE_TRANSLATIONS.includes(translation as any);
};

export const BIBLE_BOOKS_BY_NAME = {
  Genesis: 1,
  Exodus: 2,
  Leviticus: 3,
  Numbers: 4,
  Deuteronomy: 5,
  Joshua: 6,
  Judges: 7,
  Ruth: 8,
  '1 Samuel': 9,
  '2 Samuel': 10,
  '1 Kings': 11,
  '2 Kings': 12,
  '1 Chronicles': 13,
  '2 Chronicles': 14,
  Ezra: 15,
  Nehemiah: 16,
  Esther: 17,
  Job: 18,
  Psalms: 19,
  Psalm: 19,
  Proverbs: 20,
  Ecclesiastes: 21,
  'Song of Solomon': 22,
  'Song of Songs': 22,
  Isaiah: 23,
  Jeremiah: 24,
  Lamentations: 25,
  Ezekiel: 26,
  Daniel: 27,
  Hosea: 28,
  Joel: 29,
  Amos: 30,
  Obadiah: 31,
  Jonah: 32,
  Micah: 33,
  Nahum: 34,
  Habakkuk: 35,
  Zephaniah: 36,
  Haggai: 37,
  Zechariah: 38,
  Malachi: 39,
  Matthew: 40,
  Mark: 41,
  Luke: 42,
  John: 43,
  Acts: 44,
  Romans: 45,
  '1 Corinthians': 46,
  '2 Corinthians': 47,
  Galatians: 48,
  Ephesians: 49,
  Philippians: 50,
  Colossians: 51,
  '1 Thessalonians': 52,
  '2 Thessalonians': 53,
  '1 Timothy': 54,
  '2 Timothy': 55,
  Titus: 56,
  Philemon: 57,
  Hebrews: 58,
  James: 59,
  '1 Peter': 60,
  '2 Peter': 61,
  '1 John': 62,
  '2 John': 63,
  '3 John': 64,
  Jude: 65,
  Revelation: 66,
};
export type BibleBook = keyof typeof BIBLE_BOOKS_BY_NAME;

/** Function that count occurrences of a substring in a string;
 * @param {String} string               The string
 * @param {String} subString            The sub string to search for
 * @param {Boolean} [allowOverlapping]  Optional. (Default:false)
 *
 * @author Vitim.us https://gist.github.com/victornpb/7736865
 * @see Unit Test https://jsfiddle.net/Victornpb/5axuh96u/
 * @see https://stackoverflow.com/a/7924240/938822
 */
export function occurrences(
  string: string,
  subString: string,
  allowOverlapping = false,
  caseInsensitive = false,
) {
  string += '';
  subString += '';
  if (subString.length <= 0) return string.length + 1;

  let n = 0,
    pos = 0;
  const step = allowOverlapping ? 1 : subString.length;

  const lowerCaseString = string.toLowerCase();
  const lowerCaseSubString = subString.toLowerCase();

  do {
    pos = caseInsensitive
      ? lowerCaseString.indexOf(lowerCaseSubString, pos)
      : string.indexOf(subString, pos);
  } while (pos >= 0 && ++n && (pos += step));
  return n;
}

export const verseReducer =
  (type: BIBLE_TRANSLATION) => (paragraphs: JSX.Element[][], verse: Verse) => {
    if (verse.paragraph || !paragraphs[paragraphs.length - 1]) {
      paragraphs.push([]);
    }
    paragraphs[paragraphs.length - 1].push(
      <span key={`${verse.pericope}-${verse.verseNumber}`}>
        <span className='align-super text-xs'> {verse.verse} </span>
        <span dangerouslySetInnerHTML={{ __html: verse[type] }} />
      </span>,
    );
    return paragraphs;
  };

export const fetchDenominations = async () => {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/denominations`,
    {
      cache: 'force-cache',
      next: {
        tags: ['all', 'denominations'],
      },
    },
  );
  const data = (await res.json()) as Denominations | ErrorResponse;
  return data;
};

export const findMainPreviewContents = (previewContents: PreviewContent[]) => {
  let mainImage = previewContents.find(
    (previewContent) =>
      previewContent.typeName === 'video' && !previewContent.watermarked,
  );
  if (!mainImage) {
    mainImage = previewContents.find(
      (previewContent) =>
        previewContent.typeName === 'main_image' &&
        previewContent.roleInSet === 'title-1' &&
        !previewContent.watermarked &&
        !previewContent.filename.endsWith('tg_psd_demo.png'),
    );
  }
  if (!mainImage) {
    mainImage = previewContents.find(
      (previewContent) =>
        previewContent.typeName === 'main_image' &&
        previewContent.roleInSet === 'title-1' &&
        !previewContent.filename.endsWith('tg_psd_demo.png'),
    );
  }
  if (!mainImage) {
    mainImage = previewContents.find(
      (previewContent) =>
        previewContent.typeName === 'main_image' &&
        previewContent.roleInSet === 'title-2' &&
        !previewContent.watermarked &&
        !previewContent.filename.endsWith('tg_psd_demo.png'),
    );
  }
  if (!mainImage) {
    mainImage = previewContents.find(
      (previewContent) =>
        previewContent.typeName === 'main_image' &&
        previewContent.roleInSet === 'title-2' &&
        !previewContent.filename.endsWith('tg_psd_demo.png'),
    );
  }
  if (!mainImage) {
    mainImage = previewContents.find(
      (previewContent) =>
        previewContent.typeName === 'main_image' &&
        previewContent.roleInSet === 'title-3' &&
        !previewContent.watermarked &&
        !previewContent.filename.endsWith('tg_psd_demo.png'),
    );
  }
  if (!mainImage) {
    mainImage = previewContents.find(
      (previewContent) =>
        previewContent.typeName === 'main_image' &&
        previewContent.roleInSet === 'title-3' &&
        !previewContent.filename.endsWith('tg_psd_demo.png'),
    );
  }
  if (!mainImage) {
    mainImage = previewContents.find(
      (previewContent) =>
        previewContent.typeName === 'main_image' &&
        previewContent.roleInSet === 'alt-1' &&
        !previewContent.watermarked &&
        !previewContent.filename.endsWith('tg_psd_demo.png'),
    );
  }
  if (!mainImage) {
    mainImage = previewContents.find(
      (previewContent) =>
        previewContent.typeName === 'main_image' &&
        previewContent.roleInSet === 'alt-1' &&
        !previewContent.filename.endsWith('tg_psd_demo.png'),
    );
  }
  if (!mainImage) {
    mainImage = previewContents.find(
      (previewContent) =>
        previewContent.typeName === 'main_image' &&
        previewContent.roleInSet === 'alt-2' &&
        !previewContent.watermarked &&
        !previewContent.filename.endsWith('tg_psd_demo.png'),
    );
  }
  if (!mainImage) {
    mainImage = previewContents.find(
      (previewContent) =>
        previewContent.typeName === 'main_image' &&
        previewContent.roleInSet === 'alt-2' &&
        !previewContent.filename.endsWith('tg_psd_demo.png'),
    );
  }
  if (!mainImage) {
    mainImage = previewContents.find(
      (previewContent) =>
        previewContent.typeName === 'main_image' &&
        previewContent.roleInSet === 'alt-3' &&
        !previewContent.watermarked &&
        !previewContent.filename.endsWith('tg_psd_demo.png'),
    );
  }
  if (!mainImage) {
    mainImage = previewContents.find(
      (previewContent) =>
        previewContent.typeName === 'main_image' &&
        previewContent.roleInSet === 'alt-3' &&
        !previewContent.filename.endsWith('tg_psd_demo.png'),
    );
  }
  if (!mainImage) {
    mainImage = previewContents.find(
      (previewContent) =>
        previewContent.typeName === 'main_image' &&
        !previewContent.roleInSet &&
        !previewContent.watermarked &&
        !previewContent.filename.endsWith('tg_psd_demo.png'),
    );
  }

  return mainImage;
};
const words = [
  'able',
  'achieve',
  'acoustics',
  'action',
  'activity',
  'aftermath',
  'afternoon',
  'afterthought',
  'apparel',
  'appliance',
  'beginner',
  'believe',
  'bomb',
  'border',
  'boundary',
  'breakfast',
  'cabbage',
  'cable',
  'calculator',
  'calendar',
  'caption',
  'carpenter',
  'cemetery',
  'channel',
  'circle',
  'creator',
  'creature',
  'education',
  'faucet',
  'feather',
  'friction',
  'fruit',
  'fuel',
  'galley',
  'guide',
  'guitar',
  'health',
  'heart',
  'idea',
  'kitten',
  'laborer',
  'language',
  'lawyer',
  'linen',
  'locket',
  'lumber',
  'magic',
  'minister',
  'mitten',
  'money',
  'mountain',
  'music',
  'partner',
  'passenger',
  'pickle',
  'picture',
  'plantation',
  'plastic',
  'pleasure',
  'pocket',
  'police',
  'pollution',
  'railway',
  'recess',
  'reward',
  'route',
  'scene',
  'scent',
  'squirrel',
  'stranger',
  'suit',
  'sweater',
  'temper',
  'territory',
  'texture',
  'thread',
  'treatment',
  'veil',
  'vein',
  'volcano',
  'wealth',
  'weather',
  'wilderness',
  'wren',
  'wrist',
  'writer',
];
export const generatePassword = () => {
  const rand = uniqueRandomArray(words);
  const word1 = rand();
  const word2 = rand();
  const randomNumber = Math.floor(Math.random() * 100);
  return `${word1}${word2?.toUpperCase()}${randomNumber}`;
};

export const getThisWeek = async (
  week?: string,
  token?: string | null,
  ) => {
  //token = SERVER_TOKEN || token;
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/this-week${
      week ? `/${week}` : ''
    }`,
    {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined,
      cache: 'no-store',
      next: {
        tags: ['all', 'trending-social', 'this-week'],
      },
    },
  );
  const thisWeek = (await res.json()) as ThisWeek;
  return thisWeek;
};

export const fetchStudyTools = async (code: string, token?: string | null) => {
 // token = SERVER_TOKEN || token;
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/this-week/study-tools/${code}`,
    {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined,
      cache: 'force-cache',
      next: {
        tags: ['all', 'contents', 'this-week'],
      },
    },
  );
  const studyTools = (await res.json()) as StudyTools;
  return studyTools;
};

export const getAnnouncements = async () => {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/announcements`,
    {
      cache: 'no-store',
      next: {
        tags: ['all', 'announcements'],
      },
    },
  );
  const announcementsResponse = (await res.json()) as AnnouncementsResponse;
  return announcementsResponse;
};

export const fetchCurrentWeek = async (
  week = 'current',
  token?: string | null
  //cache = false,
) => {
  //token = SERVER_TOKEN || token;
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/lectionary/weeks/${
      week || 'current'
    }`,
    {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined,
      cache: 'no-store',
      next: {
        tags: ['all', 'this-week', 'lectionary'],
      },
    },
  );
  const data = (await res.json()) as Week;
  return data;
};

export const fetchCurrentNewWeek = async (
  week?: string,
  lectionary: 'R' | 'C' = 'R',
  token?: string | null,
  //cache = true,
) => {
  //token = SERVER_TOKEN || token;
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/lectionary/new-weeks/${
      week || 'current'
    }?lectionary=${['R', 'C'].includes(lectionary) ? lectionary : 'R'}`,
    {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined,
      cache:  'no-store',
      next: {
        tags: ['all', 'this-week', 'lectionary'],
      },
    },
  );
  const data = (await res.json()) as
    | NewLectionaryWeeksByReading
    | ErrorResponse;
  return data;
};

export const popupCenter = ({
  url,
  title,
  w,
  h,
}: {
  url: string;
  title: string;
  w: number;
  h: number;
}) => {
  if (typeof window === 'undefined') return;

  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.offsetWidth
      ? document.documentElement.offsetWidth
      : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.offsetHeight
      ? document.documentElement.offsetHeight
      : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${w / systemZoom},
    height=${h / systemZoom},
    top=${top},
    left=${left}
    `,
  );

  if (typeof window.focus !== 'undefined') newWindow?.focus();
};

export const fetchIllustrations = async (
  pericope: string,
  page: number,
  token?: string | null,
) => {
  //token = SERVER_TOKEN || token;
  const res = await fetch(
    `${
      process.env.NEXT_PUBLIC_API_BASE_URL
    }/search/scripture?pericope=${encodeURIComponent(
      pericope,
    )}&type=${encodeURIComponent(
      'Illustrations · Quotes · Humor',
    )}&tab=Illustrations&page=${page}`,
    {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined,
      cache: 'no-store',
      next: {
        tags: ['all', 'search', 'search-scripture'],
      },
    },
  );
  const data = (await res.json()) as ScriptureSearchResults;
  return data;
};

export const fetchContent = async (
  id: string,
  token?: string | null,
  pericopeCode?: string,
  cache = true,
) => {
  //token = SERVER_TOKEN || token;
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/contents/${id}${
      pericopeCode ? `?pericopeCode=${pericopeCode}` : ''
    }`,
    {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined,
      cache: cache ? 'force-cache' : 'no-store',
      next: {
        tags: ['all', 'contents', `content:${id}`],
      },
    },
  );
  const data = (await res.json()) as ErrorResponse | Content;
  return data;
};

export const fetchContentSlug = async (id: string) => {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/contents/${id}/slug`,
    {
      cache: 'force-cache',
      next: {
        tags: ['all', 'contents', `content:${id}`],
      },
    },
  );
  const data: ErrorResponse | ContentSlugResponse = await res.json();
  return data;
};

export const deleteContent = async (id: string, token: string) => {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/contents/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cache: 'force-cache',
      next: {
        tags: ['all', 'contents', `content:${id}`],
      },
    },
  );
  const data: ErrorResponse | SuccessResponse = await res.json();
  return data;
};

export const fetchTextAndTools = async (
  lectionaryCode: string,
  token?: string | null,
) => {
  //token = SERVER_TOKEN || token;
  const res = await fetch(
    `${
      process.env.NEXT_PUBLIC_API_BASE_URL
    }/this-week/text-and-tools/${encodeURIComponent(lectionaryCode)}`,
    {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined,
      cache: 'force-cache',
      next: {
        tags: ['all', 'contents', 'this-week'],
      },
    },
  );
  const data = (await res.json()) as ErrorResponse | SoloTextAndTools;
  return data;
};

export const renderScriptureResults = async (
  _isMySermons: boolean,
  noResults: boolean,
  scriptureResults: ScriptureSearchResults,
  type: string,
  tab: string,
  pericope: string,
  perPage: number,
  page: number,
  _illustrationsPage: number,
  illustrationsResults?: ScriptureSearchResults,
  sermon?: Content,
  user?: User | null,
  semantic?: string,
) => {
  const pericopeRow = await fetchPericope(pericope);
  return (
    <main>
      {type === 'Sermon Prep' && (
        <>
          {sermon?.pericopes?.length && (
            <SynAntTools
              term={sermon.pericopes[0].book}
              type={type}
              semantic={semantic}
              synonyms={scriptureResults.synonyms}
              antonyms={scriptureResults.antonyms}
            />
          )}
          <SermonPrepBar
            sermon={sermon || scriptureResults.results![0]}
            plural
          />
          <SermonTools sermon={sermon || scriptureResults.results![0]} />
          <SermonBible sermon={sermon || scriptureResults.results![0]} />
          <SermonIllustrations
            sermon={sermon || scriptureResults.results![0]}
            illustrationsResults={illustrationsResults}
          />
          <SermonNews sermon={sermon || scriptureResults.results![0]} />
        </>
      )}
      {type === 'Illustrations · Quotes · Humor' && (
        <IllustrationsTabs
          illustrationsTotal={
            scriptureResults.subTotals.illustrationsTotal || 0
          }
          quotesTotal={scriptureResults.subTotals.quotesTotal || 0}
          humorTotal={scriptureResults.subTotals.humorTotal || 0}
        />
      )}
      {type === 'Bulletin & Sermon Aids' && (
        <SermonAidsTabs
          sermonAidsTotal={scriptureResults.subTotals.sermonAidsTotal}
          bulletinAidsTotal={scriptureResults.subTotals.bulletinAidsTotal}
        />
      )}
      {type === 'Media' && (
        <MediaTabs
          wbTotal={scriptureResults.subTotals.wb}
          tgTotal={scriptureResults.subTotals.tg}
          uwTotal={scriptureResults.subTotals.uw}
          mnTotal={scriptureResults.subTotals.mn}
          cnTotal={scriptureResults.subTotals.cn}
        />
      )}
      <section
        className={classNames('border border-neutral-300 bg-white p-4', {
          hidden:
            (type === 'Sermon Prep' && tab && tab !== 'content') ||
            ((scriptureResults.results?.length ||
              0 ||
              scriptureResults.mediaResults?.length ||
              0) === 0 &&
              sermon &&
              (type !== 'Illustrations · Quotes · Humor' ||
                !['Quotes', 'Humor'].includes(tab))),
        })}
      >
        {(scriptureResults.results?.length ||
          0 ||
          scriptureResults.mediaResults?.length ||
          0) === 0 && (
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-1 text-sm text-neutral-500'>
              {user?.isAdmin &&
                type === 'Illustrations · Quotes · Humor' &&
                (tab === 'Quotes' ? (
                  <AddQuote />
                ) : tab === 'Illustrations' ? (
                  <AddIllustration />
                ) : (
                  <AddHumor />
                ))}
              <span>
                Showing <span className='font-bold'>0</span> results
              </span>
            </div>
          </div>
        )}
        {(scriptureResults.results?.length ||
          0 ||
          scriptureResults.mediaResults?.length ||
          0) > 0 && (
          <div className='mb-4 flex items-center justify-between'>
            <div className='flex items-center gap-1 text-sm text-neutral-500'>
              {user?.isAdmin &&
                type === 'Illustrations · Quotes · Humor' &&
                (tab === 'Quotes' ? (
                  <AddQuote />
                ) : tab === 'Illustrations' ? (
                  <AddIllustration />
                ) : (
                  <AddHumor />
                ))}
              <span>
                Showing{' '}
                <span className='font-bold'>{(page - 1) * perPage + 1}</span> to{' '}
                <span className='font-bold'>
                  {scriptureResults.total < page * perPage
                    ? scriptureResults.total
                    : page * perPage}
                </span>{' '}
                of <span className='font-bold'>{scriptureResults.total}</span>{' '}
                results
              </span>
            </div>
            <Pagination page={page} lastPage={scriptureResults.lastPage} />
          </div>
        )}
        {type === 'Media' && (
          <section className='mb-4 grid grid-cols-1 gap-4 lg:grid-cols-4'>
            {scriptureResults.mediaResults?.map((result) => {
              let previewContent = result.gracewayPreviewContents.find(
                (previewContent) =>
                  previewContent.typeName === 'main_image' &&
                  previewContent.roleInSet === 'title-1',
              );
              if (!previewContent) {
                previewContent = result.gracewayPreviewContents.find(
                  (previewContent) =>
                    previewContent.typeName === 'main_image' &&
                    !previewContent.roleInSet,
                );
              }

              return (
                <Link
                  key={result.id}
                  className='group inline-block border border-neutral-300'
                  href={`/media/${slugify(result.name).toLowerCase()}/${
                    result.id
                  }`}
                >
                  <div className='relative bg-black pt-[56.25%]'>
                    <Image
                      className={classNames('absolute left-0 w-full', {
                        'top-0': result.productType !== 'Ultra-Wides',
                        'top-[25%]': result.productType === 'Ultra-Wides',
                      })}
                      width={672}
                      height={378}
                      src={previewContent?.asset || ''}
                      alt={result.name}
                    />
                  </div>
                  <div className='p-4 text-sm'>
                    <div className='font-bold group-hover:underline'>
                      {result.name}
                    </div>
                    <div>{result.subTitle}</div>
                  </div>
                </Link>
              );
            })}
          </section>
        )}
        {type !== 'Media' &&
          scriptureResults.results?.map((result, index) => (
            <>
              {index === 0 && <hr className='my-4' />}
              <SearchResult
                result={result}
                index={index + (page - 1) * perPage + 1}
                allIds={scriptureResults.allIds}
                type={type}
                hideScripture={
                  !result.pericopes?.find(
                    (p) => p.number === scriptureResults.pericope,
                  )
                }
                pericopeCode={pericope}
              />
              <div className='clear-both'></div>
              <hr className='my-4' />
            </>
          ))}
        {(scriptureResults.results?.length ||
          0 ||
          scriptureResults.mediaResults?.length ||
          0) > 0 && (
          <div className='mb-4 flex items-center justify-between'>
            <div className='text-sm text-neutral-500'>
              Showing{' '}
              <span className='font-bold'>{(page - 1) * perPage + 1}</span> to{' '}
              <span className='font-bold'>
                {scriptureResults.total < page * perPage
                  ? scriptureResults.total
                  : page * perPage}
              </span>{' '}
              of <span className='font-bold'>{scriptureResults.total}</span>{' '}
              results
            </div>
            <Pagination page={page} lastPage={scriptureResults.lastPage} />
          </div>
        )}
      </section>
      {(scriptureResults.results?.length ||
        0 ||
        scriptureResults.mediaResults?.length ||
        0) === 0 &&
        sermon &&
        (type !== 'Illustrations · Quotes · Humor' ||
          !['Quotes', 'Humor'].includes(tab)) && (
          <Sermon
            className='mt-4'
            sermon={sermon}
            illustrationsResults={illustrationsResults}
            noResults={noResults}
            mySermonsMessage={`
            <p>
              Your search has yielded no results because you haven't created any ${
                tab || type
              } on ${
                pericopeRow.fullReference
              }. If you wish to see Sermons.com resources you must flip to Sermons.com by clicking the red circle (with the double arrows) and performing your search again. If you intend on staying here in mySermons, then reperform your search for something you yourself have created, or go back to the Create area by choosing Create inside the red drop down menu.
            </p>
            <p>
              Please download the iSermons app today by searching for iSermons in the Apple and Google stores, to access all that Sermons.com has to offer, including mobile access to 1) All of the Sermons.com resources, 2) Your personal mySermons library, and 3) Use of the sermon Create tools.
            </p>
          `}
          />
        )}
    </main>
  );
};

export const renderLectionaryResults = async (
  _isMySermons: boolean,
  noResults: boolean,
  lectionaryResults: LectionarySearchResults,
  results: (ContentWithSnippet | MediaContent)[],
  type: string,
  tab: string,
  pericope: string | undefined,
  illustrationsTotal: number,
  quotesTotal: number,
  humorTotal: number,
  sermonAidsTotal: number,
  bulletinAidsTotal: number,
  total: number,
  page: number,
  perPage: number,
  lastPage: number,
  pageParam: string,
  allIds: string[],
  _illustrationsPage: number,
  illustrationsResults?: ScriptureSearchResults,
  sermon?: Content,
  user?: User | null,
) => {
  const pericopeRow = pericope
    ? await fetchPericope(pericope)
    : ({} as Pericope);
  return (
    <main>
      <LectionaryTabs
        hideTotals={
          type === 'Illustrations · Quotes · Humor' ||
          type === 'Bulletin & Sermon Aids'
        }
        otTotal={lectionaryResults.otTotal || 0}
        psTotal={lectionaryResults.psTotal || 0}
        epTotal={lectionaryResults.epTotal || 0}
        gsTotal={lectionaryResults.gsTotal || 0}
        kwTotal={
          lectionaryResults.kwResults && lectionaryResults.kwResults.total
        }
      />
      {type === 'Illustrations · Quotes · Humor' && (
        <IllustrationsTabs
          illustrationsTotal={illustrationsTotal}
          quotesTotal={quotesTotal}
          humorTotal={humorTotal}
        />
      )}
      {type === 'Bulletin & Sermon Aids' && (
        <SermonAidsTabs
          sermonAidsTotal={sermonAidsTotal}
          bulletinAidsTotal={bulletinAidsTotal}
        />
      )}
      {results.length === 0 && (
        <div className='flex items-center gap-1 border border-neutral-300 bg-white p-4'>
          {user?.isAdmin &&
            type === 'Illustrations · Quotes · Humor' &&
            (tab === 'Quotes' ? (
              <AddQuote />
            ) : tab === 'Illustrations' ? (
              <AddIllustration />
            ) : (
              <AddHumor />
            ))}
          <p className='text-sm text-neutral-500'>
            There are 0 results for your search.
          </p>
        </div>
      )}
      <section
        className={classNames('bg-white', {
          'border border-neutral-300 p-4': results.length > 0,
        })}
      >
        {results.length > 0 && (
          <div className='mb-4 flex items-center justify-between'>
            <div className='flex items-center gap-1 text-sm text-neutral-500'>
              {user?.isAdmin &&
                type === 'Illustrations · Quotes · Humor' &&
                (tab === 'Quotes' ? (
                  <AddQuote />
                ) : tab === 'Illustrations' ? (
                  <AddIllustration />
                ) : (
                  <AddHumor />
                ))}
              <span>
                Showing{' '}
                <span className='font-bold'>{(page - 1) * perPage + 1}</span> to{' '}
                <span className='font-bold'>
                  {total < page * perPage ? total : page * perPage}
                </span>{' '}
                of <span className='font-bold'>{total}</span> results
              </span>
            </div>
            <Pagination page={page} lastPage={lastPage} pageParam={pageParam} />
          </div>
        )}
        {type === 'Media' && results.length > 0 && (
          <section className='mb-4 grid grid-cols-1 gap-4 lg:grid-cols-4'>
            {(results as MediaContent[]).map((result) => {
              const previewContent = findMainPreviewContents(
                result.gracewayPreviewContents,
              );

              return (
                <Link
                  key={result.id}
                  className='group inline-block border border-neutral-300'
                  href={`/media/${slugify(result.name).toLowerCase()}/${
                    result.id
                  }`}
                >
                  <div className='relative bg-black pt-[56.25%]'>
                    <Image
                      className={classNames('absolute left-0 top-0 w-full', {
                        'top-[25%]': result.productType === 'Ultra-Wides',
                      })}
                      width={672}
                      height={378}
                      src={previewContent?.asset || ''}
                      alt={result.name}
                    />
                  </div>
                  <div className='p-4 text-sm'>
                    <div className='font-bold group-hover:underline'>
                      {result.name}
                    </div>
                    <div>{result.subTitle}</div>
                  </div>
                </Link>
              );
            })}
          </section>
        )}
        {type !== 'Media' &&
          results.length > 0 &&
          (results as ContentWithSnippet[]).map((result, index) => (
            <>
              {index === 0 && <hr className='my-4' />}
              <SearchResult
                result={result}
                index={index + (page - 1) * perPage + 1}
                allIds={allIds}
                type={type}
              />
              <div className='clear-both'></div>
              <hr className='my-4' />
            </>
          ))}
        {results.length === 0 &&
          sermon &&
          (type !== 'Illustrations · Quotes · Humor' ||
            !['Quotes', 'Humor'].includes(tab)) &&
          type !== 'Media' && (
            <Sermon
              sermon={sermon}
              illustrationsResults={illustrationsResults}
              noResults={noResults}
              mySermonsMessage={`
              <p>
                Your search has yielded no results because you haven't created any ${
                  tab || type
                } on ${
                  pericopeRow.fullReference
                }. If you wish to see Sermons.com resources you must flip to Sermons.com by clicking the red circle (with the double arrows) and performing your search again. If you intend on staying here in mySermons, then reperform your search for something you yourself have created, or go back to the Create area by chosing Create inside the red drop down menu.
              </p>
              <p>
                Please download the iSermons app today by searching for iSermons in the Apple and Google stores, to access all that Sermons.com has to offer, including mobile access to 1) All of the Sermons.com resources, 2) Your personal mySermons library, and 3) Use of the sermon Create tools.
              </p>
            `}
            />
          )}
        {results.length > 0 && (
          <div className='mb-4 flex items-center justify-between'>
            <div className='text-sm text-neutral-500'>
              Showing{' '}
              <span className='font-bold'>{(page - 1) * perPage + 1}</span> to{' '}
              <span className='font-bold'>
                {total < page * perPage ? total : page * perPage}
              </span>{' '}
              of <span className='font-bold'>{total}</span> results
            </div>
            <Pagination page={page} lastPage={lastPage} pageParam={pageParam} />
          </div>
        )}
      </section>
    </main>
  );
};

export const fetchBulletins = async (sunday: Moment, cache = true) => {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/bulletins/${sunday.format(
      'YYYY-MM-DD',
    )}`,
    {
      cache: cache ? 'force-cache' : 'no-store',
      next: {
        tags: ['all', 'bulletins'],
      },
    },
  );
  const data = (await res.json()) as BulletinsResponse;
  return data;
};

export const fetchBookmarks = async (token?: string | null) => {
  //token = SERVER_TOKEN || token;
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/contents/bookmarked-sermons`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cache: 'no-store',
      next: {
        tags: ['all', 'bookmarks'],
      },
    },
  );
  const data: BookmarkedSermons | ErrorResponse = await res.json();
  return data;
};

export const fetchPericope = async (pericope: string) => {
  const res = await fetch(
    `${
      process.env.NEXT_PUBLIC_API_BASE_URL
    }/bible/pericope/${encodeURIComponent(pericope)}`,
    {
      cache: 'force-cache',
      next: {
        tags: ['all', 'bible'],
      },
    },
  );
  const data = (await res.json()) as Pericope;
  return data;
};

export const fetchBooks = async () => {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/bible/books`,
    {
      cache: 'force-cache',
      next: {
        tags: ['all', 'bible'],
      },
    },
  );
  const data = (await res.json()) as BibleBooks;
  return data;
};

export const fetchWeeks = async (token?: string | null, cache = true) => {
  //token = SERVER_TOKEN || token;
  const [resA, resB, resC] = await Promise.all([
    fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/lectionary/weeks?cycle=A`, {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined,
      cache: cache ? 'force-cache' : 'no-store',
      next: {
        tags: ['all', 'lectionary'],
      },
    }),
    fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/lectionary/weeks?cycle=B`, {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined,
      cache: cache ? 'force-cache' : 'no-store',
      next: {
        tags: ['all', 'lectionary'],
      },
    }),
    fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/lectionary/weeks?cycle=C`, {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined,
      cache: cache ? 'force-cache' : 'no-store',
      next: {
        tags: ['all', 'lectionary'],
      },
    }),
  ]).catch((e) => {
    console.error(e);
    throw e;
  });
  const yearAWeeks = (await resA.json()) as WeeksResponse;
  const yearBWeeks = (await resB.json()) as WeeksResponse;
  const yearCWeeks = (await resC.json()) as WeeksResponse;
  return {
    a: yearAWeeks.lectionaryWeeks,
    b: yearBWeeks.lectionaryWeeks,
    c: yearCWeeks.lectionaryWeeks,
  };
};

export const fetchNewWeeks = async (
  token?: string | null,
  lectionary: 'R' | 'C' = 'R',

) => {
  //token = SERVER_TOKEN || token;
  const [resA, resB, resC] = await Promise.all([
    fetch(
      `${
        process.env.NEXT_PUBLIC_API_BASE_URL
      }/lectionary/new-weeks?cycle=A&lectionary=${
        ['R', 'C'].includes(lectionary) ? lectionary : 'R'
      }`,
      {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : undefined,
        cache: 'no-store',
        next: {
          tags: ['all', 'lectionary'],
        },
      },
    ),
    fetch(
      `${
        process.env.NEXT_PUBLIC_API_BASE_URL
      }/lectionary/new-weeks?cycle=B&lectionary=${
        ['R', 'C'].includes(lectionary) ? lectionary : 'R'
      }`,
      {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : undefined,
        cache: 'no-store',
        next: {
          tags: ['all', 'lectionary'],
        },
      },
    ),
    fetch(
      `${
        process.env.NEXT_PUBLIC_API_BASE_URL
      }/lectionary/new-weeks?cycle=C&lectionary=${
        ['R', 'C'].includes(lectionary) ? lectionary : 'R'
      }`,
      {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : undefined,
        cache: 'no-store',
        next: {
          tags: ['all', 'lectionary'],
        },
      },
    ),
  ]).catch((e) => {
    console.error(e);
    throw e;
  });
  const yearAWeeks = (await resA.json()) as NewWeeksResponse | ErrorResponse;
  const yearBWeeks = (await resB.json()) as NewWeeksResponse | ErrorResponse;
  const yearCWeeks = (await resC.json()) as NewWeeksResponse | ErrorResponse;
  return 'statusCode' in yearAWeeks ||
    'statusCode' in yearBWeeks ||
    'statusCode' in yearCWeeks
    ? (Object.assign({}, yearAWeeks, yearBWeeks, yearCWeeks) as ErrorResponse &
        NewWeeksResponse)
    : {
        a: yearAWeeks.lectionaryWeeks,
        b: yearBWeeks.lectionaryWeeks,
        c: yearCWeeks.lectionaryWeeks,
      };
};

export const fetchScriptureSearch = async (
  book: number,
  chapter: number,
  pericope: string,
  type: string,
  tab: string,
  page: number,
  token?: string | null,
  isMySermons = false,
) => {
  //token = SERVER_TOKEN || token;
  const res = await fetch(
    `${
      process.env.NEXT_PUBLIC_API_BASE_URL
    }/search/scripture?book=${book}&chapter=${chapter}&pericope=${encodeURIComponent(
      pericope,
    )}&type=${encodeURIComponent(type)}&tab=${encodeURIComponent(
      tab,
    )}&page=${page}${isMySermons ? '&isMySermons' : ''}`,
    {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined,
      cache: 'no-store',
      next: {
        tags: ['all', 'search', 'search-scripture'],
      },
    },
  );
  const data = (await res.json()) as ScriptureSearchResults;
  return data;
};

export const updateNewLectionaryResources = async (
  id: string,
  body: string,
  token?: string | null,
) => {
  //token = SERVER_TOKEN || token;
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/lectionary/new-weeks/${id}/resources`,
    {
      method: 'PUT',
      body: JSON.stringify({ body }),
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        : {
            'Content-Type': 'application/json',
          },
      cache: 'no-store',
      next: {
        tags: ['all', 'lectionary'],
      },
    },
  );
  const data = (await res.json()) as SuccessResponse | ErrorResponse;
  return data;
};

export const fetchSynonymsAndAntonyms = async (
  page = 1,
  sort: SYN_ANT_SORT_OPTION = 'A-Z',
) => {
  const url = new URL(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/search/synonyms-antonyms`,
  );
  url.searchParams.set('page', `${page}`);
  url.searchParams.set('sort', sort);
  const res = await fetch(url.href, {
    cache: 'no-store',
    next: {
      tags: ['all', 'syn-ant'],
    },
  });
  const data = (await res.json()) as SynonymsAntonymsResponse | ErrorResponse;
  return data;
};

export const updateSynonymsAndAntonyms = async (
  editedWords: LexicalEntry[],
) => {
  const url = new URL(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/search/synonyms-antonyms`,
  );
  const res = await fetch(url.href, {
    method: 'PUT',
    body: JSON.stringify({ editedWords }),
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'no-store',
    next: {
      tags: ['all', 'syn-ant'],
    },
  });
  const data = (await res.json()) as SuccessResponse | ErrorResponse;
  return data;
};

export const createSynonymsAndAntonyms = async (newWord: LexicalEntry) => {
  const url = new URL(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/search/synonyms-antonyms`,
  );
  const res = await fetch(url.href, {
    method: 'POST',
    body: JSON.stringify(newWord),
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'no-store',
    next: {
      tags: ['all', 'syn-ant'],
    },
  });
  const data = (await res.json()) as SuccessResponse | ErrorResponse;
  return data;
};

export const deleteSynonymsAndAntonyms = async (word: string) => {
  const url = new URL(
    `${
      process.env.NEXT_PUBLIC_API_BASE_URL
    }/search/synonyms-antonyms/${encodeURIComponent(word)}`,
  );
  const res = await fetch(url.href, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'no-store',
    next: {
      tags: ['all', 'syn-ant'],
    },
  });
  const data = (await res.json()) as SuccessResponse | ErrorResponse;
  return data;
};

export const toggleUserRenewalCouponEnabled = async (
  userId: number,
  token?: string | null,
) => {
  //token = SERVER_TOKEN || token;
  const url = new URL(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/users/${userId}/toggle-renewal-coupons-enabled`,
  );
  const res = await fetch(url.href, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...(token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {}),
    },
    cache: 'no-store',
    next: {
      tags: ['all', 'users'],
    },
  });
  const data = (await res.json()) as SuccessResponse | ErrorResponse;
  return data;
};

export function isError(
  errorResponse: object | string | ErrorResponse | null | undefined,
): errorResponse is ErrorResponse {
  return (
    typeof errorResponse === 'object' &&
    !!errorResponse &&
    'statusCode' in errorResponse
  );
}

export const fetchHelp = async (token?: string | null) => {
 // token = SERVER_TOKEN || token;
  const url = new URL(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/my-sermons/help`,
  );
  const res = await fetch(url.href, {
    headers: {
      ...(token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {}),
    },
    cache: 'force-cache',
    next: {
      tags: ['all', 'my-sermons', 'my-sermons-help'],
    },
  });
  const data = (await res.json()) as HelpResponse | ErrorResponse;
  return data;
};

export const getSermonPath = (sermon: Content | Partial<Content>) => {
  const slug = sermon.contentTitle
    ? encodeURIComponent(slugify(sermon.contentTitle).toLowerCase())
    : '_';
  return `/sermon/${slug}/${sermon.id}`;
};

export const fetchSermonSeries = async () => {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/sermon-series/categories`,
    {
      cache: 'no-store',
      next: {
        tags: ['all', 'sermon-series'],
      },
    },

  );
  const data = (await res.json()) as SeriesCategoriesResponse | ErrorResponse;
  return data;
};

export const createContent = async (
  sermon: ContentDto,
  token?: string | null,
) => {
  //token = SERVER_TOKEN || token;
  const url = new URL(`${process.env.NEXT_PUBLIC_API_BASE_URL}/contents`);
  const res = await fetch(url.href, {
    method: 'POST',
    body: JSON.stringify(sermon),
    headers: {
      'Content-Type': 'application/json',
      ...(token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {}),
    },
    cache: 'no-store',
    next: {
      tags: ['all', 'contents', 'my-sermons', 'my-sermons-contents'],
    },
  });
  const data = (await res.json()) as SuccessResponse<Content> | ErrorResponse;
  return data;
};

export const toQueryString = <T extends URLSearchParams>(searchParams: T) => {
  return searchParams.size > 0 ? `?${searchParams}` : '';
};

export const getHtmlClasses = (html: string) => {
  const classRegex = /class=("([^"]+?)"|'([^']+?)')/g;
  const classes = new Set<string>();

  let match;
  while ((match = classRegex.exec(html)) !== null) {
    (match[2] || match[3])
      .split(' ')
      .forEach((className) => classes.add(className));
  }

  return Array.from(classes);
};

export const fetchMySermons = async (
  token?: string | null,
  page = 1,
  isDraft = false,
) => {
 // token = SERVER_TOKEN || token;
  const url = new URL(`${process.env.NEXT_PUBLIC_API_BASE_URL}/contents`);
  url.searchParams.set('page', `${page}`);
  if (isDraft) {
    url.searchParams.set('isDraft', '');
  }

  const res = await fetch(url.href, {
    headers: {
      'Content-Type': 'application/json',
      ...(token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {}),
    },
    cache: 'force-cache',
    next: {
      tags: ['all', 'contents', 'my-sermons', 'my-sermons-contents'],
    },
  });

  const result: ContentResponse | ErrorResponse = await res.json();
  return result;
};

export const fetchBookmarkedContent = async (
  token?: string | null,
  page = 1,
) => {
  //token = SERVER_TOKEN || token;
  const url = new URL(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/contents/bookmarks`,
  );
  url.searchParams.set('page', `${page}`);

  const res = await fetch(url.href, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    cache: 'force-cache',
    next: {
      tags: ['all', 'contents', 'bookmarks'],
    },
  });
  const data: ContentResponse | ErrorResponse = await res.json();
  return data;
};

export const fetchMySermonsExplanation = async (type: string) => {
  const url = new URL(
    `${
      process.env.NEXT_PUBLIC_API_BASE_URL
    }/my-sermons/explanations/${encodeURIComponent(type)}`,
  );

  const res = await fetch(url.href, {
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'force-cache',
    next: {
      tags: ['all', 'my-sermons', `my-sermons-${type}-explanation`],
    },
  });

  const result: null | string | ErrorResponse = await res.json();
  return result;
};

export const savehMySermonsExplanation = async (
  type: string,
  body: string,
  token: string,
) => {
  const url = new URL(
    `${
      process.env.NEXT_PUBLIC_API_BASE_URL
    }/my-sermons/explanations/${encodeURIComponent(type)}`,
  );

  const res = await fetch(url.href, {
    method: 'POST',
    body,
    headers: {
      'Content-Type': 'text/plain',
      Authorization: `Bearer ${token}`,
    },
    cache: 'no-store',
    next: {
      tags: ['all', 'my-sermons', `my-sermons-${type}-explanation`],
    },
  });

  const result: string | ErrorResponse = await res.json();
  return result;
};

export const hasContent = (user: User, content: Content) => {
  if (
    user.isExpired ||
    (content.userId &&
      +content.userId !== user.id &&
      ((content.contentType === '3' && !user.hasChildrenSermons) ||
        (content.contentType !== '3' && !user.hasIllustrations)))
  ) {
    return false;
  }
  return true;
};

export const getIllustrations = async (week?: string) => {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/this-week/illustrations${
      week ? `/${week}` : ''
    }`,
    {
      cache: 'force-cache',
      next: {
        tags: ['all', 'this-week'],
      },
    },
  );
  const thisWeek = (await res.json()) as IllustrationsResponse;
  return thisWeek;
};

export const fetchChapters = async (bookNumber: number) => {
  const url = new URL(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/bible/books/${bookNumber}/chapters`,
  );

  const res = await fetch(url.href, {
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'force-cache',
    next: {
      tags: ['all', 'bible'],
    },
  });

  const result: ChaptersResponse | ErrorResponse = await res.json();
  return result;
};

export const fetchVerseStrings = async (
  book: number,
  selectedChapter: number,
) => {
  const url = new URL(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/bible/books/${book}/chapters/${selectedChapter}/verse-strings`,
  );

  const res = await fetch(url.href, {
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'force-cache',
    next: {
      tags: ['all', 'bible'],
    },
  });

  const result: VerseStringsResponse | ErrorResponse = await res.json();
  return result;
};

export const fetchSuggestions = async (term: string) => {
  const url = new URL(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/search/suggestions`,
  );
  url.searchParams.set('query', term);

  const res = await fetch(url.href, {
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'force-cache',
    next: {
      tags: ['all', 'search', 'suggestions'],
    },
  });
  const data: Suggestion[] | ErrorResponse = await res.json();
  return data;
};

export const fetchDictionaryWord = async (word: string) => {
  if (!word) {
    return null;
  }
  const url = new URL(
    `${
      process.env.NEXT_PUBLIC_API_BASE_URL
    }/dictionary-words/word/${encodeURIComponent(word)}`,
  );

  const res = await fetch(url.href, {
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'force-cache',
    next: {
      tags: ['all', 'dictionary'],
    },
  });
  const data: NewDictionaryWord | null | ErrorResponse = await res.json();
  return data;
};

export function cloneDeep<T>(source: T): T {
  if (!isObject(source)) {
    return source;
  }

  const output: Record<string, unknown> = {};

  for (const key in source) {
    output[key] = cloneDeep(source[key]);
  }

  return output as T;
}

/**
 * Check if provided parameter is plain object
 * @param item
 * @returns boolean
 */
export function isObject(item: unknown): item is Record<string, unknown> {
  return (
    item !== null && typeof item === 'object' && item.constructor === Object
  );
}

/**
 * Merge and deep copy the values of all of the enumerable own properties of target object from source object to a new object
 * @param target The target object to get properties from.
 * @param source The source object from which to copy properties.
 * @return A new merged and deep copied object.
 */
export function mergeDeep<T extends object, S extends object>(
  target: T,
  source: S,
): T & S {
  if (isObject(source) && Object.keys(source).length === 0) {
    return cloneDeep({ ...target, ...source });
  }

  const output = { ...target, ...source };

  if (isObject(source) && isObject(target)) {
    for (const key in source) {
      if (isObject(source[key]) && key in target && isObject(target[key])) {
        (output as Record<string, unknown>)[key] = mergeDeep(
          target[key] as object,
          source[key] as object,
        );
      } else {
        (output as Record<string, unknown>)[key] = isObject(source[key])
          ? cloneDeep(source[key])
          : source[key];
      }
    }
  }

  return output;
}

export const toServerTime = (
  dateTime?: string | Date | undefined | null | number | Moment,
) => {
  if (moment.isMoment(dateTime)) {
    return moment.tz(dateTime.toISOString(true).slice(0, 23), SERVER_TZ);
  } else if (
    typeof dateTime === 'string' ||
    typeof dateTime === 'number' ||
    dateTime instanceof Date
  ) {
    return moment.tz(
      moment(dateTime === '' ? undefined : dateTime)
        .toISOString(true)
        .slice(0, 23),
      SERVER_TZ,
    );
  }
  return moment().tz(SERVER_TZ);
};

export const fromServerTime = (
  dateTime?: string | Date | undefined | null | number | Moment,
) => {
  if (moment.isMoment(dateTime)) {
    return moment(dateTime.toISOString(true).slice(0, 23));
  } else if (
    typeof dateTime === 'string' ||
    typeof dateTime === 'number' ||
    dateTime instanceof Date
  ) {
    return moment(
      moment
        .tz(dateTime === '' ? undefined : dateTime, SERVER_TZ)
        .toISOString(true)
        .slice(0, 23),
    );
  }
  return moment();
};

export const readableToIterator = async function* (stream: Readable) {
  for await (const chunk of stream) {
    yield chunk;
  }
};

export const generatorToStream = <A, B, C>(
  iterator: AsyncGenerator<A, B, C>,
) => {
  return new ReadableStream({
    async pull(controller) {
      const { value, done } = await iterator.next();

      if (done) {
        controller.close();
      } else {
        controller.enqueue(value);
      }
    },
  });
};

export const parseRequestUrl = (request: NextRequest) => {
  const url = new URL(
    `${request.headers.get('x-forwarded-proto') || request.nextUrl.protocol.slice(0, -1)}://${request.headers.get('x-forwarded-host') || request.headers.get('host')}${request.nextUrl.pathname}${request.nextUrl.search}`,
  );
  return url;
};

export const streamToString = async (stream: ReadableStream<Uint8Array>) => {
  const reader = stream.getReader();
  let result = '';
  const decoder = new TextDecoder();

  let done = false;

  while (!done) {
    const { value, done: streamDone } = await reader.read();
    done = streamDone;
    if (value) {
      result += decoder.decode(value, { stream: true });
    }
  }

  // Decode any remaining characters
  result += decoder.decode();

  return result;
};

export const fetchVerses = async (pericope: string) => {
  const url = new URL(`${process.env.NEXT_PUBLIC_API_BASE_URL}/bible`);
  url.searchParams.set('pericope', `${pericope}`);
  const res = await fetch(url.href, {
    cache: 'force-cache',
    next: {
      tags: ['all', 'bible'],
    },
  });
  const data = (await res.json()) as BibleResponse;
  return data;
};
